import { Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"

const GridItem = props => (
    <div className={`themes-grid-item ${props.item.slug}/`} key={props.index}>
        <div className="themes-grid-item-image">
            <Link to={`/theme/${props.item.slug}/`}>
                {props.item.featuredImage.imageFile !== null &&
                    Object.keys(props.item.featuredImage.imageFile).indexOf(
                        "childImageSharp"
                    ) !== -1 && (
                        <Img
                            fluid={
                                props.item.featuredImage.imageFile
                                    .childImageSharp.fluid
                            }
                            alt={props.item.title}
                        />
                    )}
            </Link>
        </div>
        <div className="themes-grid-item-info">
            <div className="themes-grid-item-text">
                <h4 className="themes-grid-item-title">{props.item.title}</h4>
                <div className="themes-grid-item-desc">
                    {props.item.subtitle}
                </div>
            </div>

            <div className="themes-grid-item-action">
                <Link
                    to={`/theme/${props.item.slug}/`}
                    className="themes-grid-item-button-more secondary-alt-button"
                >
                    View More
                </Link>

                <a
                    href={`https://demo.quemalabs.com/${props.item.slug}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="themes-grid-item-button-preview secondary-button"
                >
                    Preview
                </a>
            </div>
        </div>
    </div>
)

export default GridItem
