import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GridItem from "../components/gridItem"
import CtaThemeClub from "../components/ctaThemeClub"

import Img from "gatsby-image"

const IndexPage = ({ data, location }) => {
    const featuredTax = data.wpgraphql.qlThemeCategories.edges[0].node
    const themes = featuredTax.qlThemes.edges

    return (
        <Layout cssClasses="home">
            <SEO
                title="Home"
                description="Free and Premium WordPress Themes for these modern times"
                image={location.origin + "/images/open-graph-image.jpg"}
            />

            <div className="welcome-box">
                <h2 className="welcome-box-title">
                    WordPress Themes for these modern times
                </h2>
                <p className="welcome-box-content">
                    Helping 14.000+ users with the fastest and easiest way to
                    start a website.
                </p>
                <div className="welcome-box-action">
                    <Link to="/theme-club/" className="hero-button">
                        Join The Club
                    </Link>
                    <Link to="/themes/" className="hero-transparent-button">
                        View Themes
                    </Link>
                </div>

                <div className="welcome-box-image">
                    <Img
                        fixed={data.heroImage.childImageSharp.fixed}
                        alt="Quema Labs"
                    />
                </div>
            </div>

            <div className="themes-grid">
                {themes.map((theme, index) => (
                    <GridItem
                        item={theme.node}
                        index={`item-` + index}
                        key={index}
                    />
                ))}
            </div>

            <div className="more-themes-wrap">
                <Link
                    to="/themes/"
                    className="more-themes-button secondary-button"
                >
                    More Themes
                </Link>
            </div>

            <CtaThemeClub />
        </Layout>
    )
}

export default IndexPage

// Query passed as data to IndexPage
export const pageQuery = graphql`
    query {
        wpgraphql {
            # First we get the Taxonomy Featured and then the themes inside that tax
            qlThemeCategories(where: { slug: "featured" }) {
                edges {
                    node {
                        id
                        qlThemes {
                            edges {
                                node {
                                    title
                                    slug
                                    subtitle
                                    featuredImage {
                                        sourceUrl
                                        mediaItemId
                                        modified
                                        imageFile {
                                            childImageSharp {
                                                fluid(
                                                    maxWidth: 500
                                                    quality: 75
                                                ) {
                                                    base64
                                                    aspectRatio
                                                    src
                                                    srcSet
                                                    sizes
                                                }
                                            }
                                        }
                                    }
                                    qlThemeCategories {
                                        edges {
                                            node {
                                                slug
                                                name
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        # Get the Hero Image from the File System
        heroImage: file(relativePath: { eq: "hero.png" }) {
            childImageSharp {
                fixed(width: 500) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`
