import { Link } from "gatsby"
import React from "react"

const CtaThemeClub = () => (
  <div className="cta-theme-club">
    <span className="cta-theme-club-box1"></span>
    <span className="cta-theme-club-box2"></span>
    <div className="cta-theme-club-content">
      <h3 className="cta-theme-club-title">Do you like more than one theme?</h3>
      <p className="cta-theme-club-desc">
        Download all the themes sold on our site for one low price
      </p>
    </div>
    <div className="cta-theme-club-action">
      <Link to="/theme-club" className="hero-button">
        Join The Club
      </Link>
    </div>
  </div>
)

export default CtaThemeClub
